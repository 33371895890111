.logContainer {
    height: 100%;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .logTextContainer {
        display: block;
        height: calc(100% - 1em);
        width: 100%;
        margin: 0;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }

    .logText {
        white-space: pre-wrap;       /* Since CSS 2.1 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -pre-wrap;      /* Opera 4-6 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        word-wrap: break-word;       /* Internet Explorer 5.5+ */
        font-size: 15px;
        line-height: 20px;
    }

    .logText > span {
        counter-increment: linenumber;
        text-align: left;
        float: left;
        clear: left;
        margin-left: 0.5em;
    }

    .logNumber {
        display: inline-block;
        min-width: 40px;
        margin-right: 0.5rem;
        text-align: right;
        user-select: none; 
        -moz-user-select: none; 
        -khtml-user-select: none; 
    }

    // .logText > span {
    //     counter-increment: linenumber;
    //     text-align: left;
    //     float: left;
    //     clear: left;
    //     margin-left: 0.5em;
    // }

    // .logText > span::before {
    //     float: left;
    //     content: counter(linenumber);
    //     margin-right: 0.6rem;
    //     opacity: 0.8;
    //     padding-left: 10;
    //     width: 50px;
    //     margin-left: auto;
    //     text-align: right;
    // }
}
